import { BothPlatform, MyData, Protect } from "src/assets/svgs";
import { cls } from "src/theme/colors";
import styled from "styled-components";

function DataTechExplained() {
  return (
    <S.Container>
      <S.TextBox>
        <S.MainText>이제는 [ 데이터테크 ]</S.MainText>
        <S.SubText>더 간편하게 연동하고 데이터 조회까지!</S.SubText>
      </S.TextBox>
      <S.IconBox>
        <S.IconContent>
          <Protect />
          <S.IconText>
            개인정보 유출
            <br />
            걱정 없이
          </S.IconText>
        </S.IconContent>
        <S.IconContent>
          <MyData />
          <S.IconText>
            마이데이터는
            <br />내 휴대폰에만 저장
          </S.IconText>
        </S.IconContent>
        <S.IconContent>
          <BothPlatform />
          <S.IconText>
            안드로이드, 애플
            <br />
            모두 사용 가능
          </S.IconText>
        </S.IconContent>
      </S.IconBox>
    </S.Container>
  );
}

export default DataTechExplained;

const S = {
  Container: styled.div`
    z-index: 10;
    width: calc(100% - 480px);
    height: 300px;
    background-color: ${cls.mainBlue7};
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 80px;
    position: absolute;
    left: 240px;
    bottom: -220px;
  `,
  TextBox: styled.div`
    display: flex;
    flex-direction: column;
  `,
  MainText: styled.p`
    color: ${cls.white};
    font-size: 40px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -0.92px;
    white-space: nowrap;
  `,
  SubText: styled.p`
    color: ${cls.white};
    font-size: 30px;
    letter-spacing: -0.69px;
    white-space: nowrap;
  `,
  IconBox: styled.div`
    display: flex;
    align-items: center;
  `,
  IconContent: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  `,
  IconText: styled.p`
    color: ${cls.white};
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.414px;
    margin-top: 20px;
  `,
};
