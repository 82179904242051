import React, { useState } from "react";
import { Arrow } from "src/assets/svgs";

import styled from "styled-components";
import BannerScroll from "./BannerScroll";

function PointUsage() {
  const [seq, setSeq] = useState<"first" | "last">("first");

  const handleDirectionMouseOver = (e: React.MouseEvent<SVGSVGElement>) => {
    const { currentTarget } = e;

    if (currentTarget.id === "right") {
      setSeq("last");
    } else {
      setSeq("first");
    }
  };

  const handleDirectionMouseOut = () => {
    setSeq("first");
  };

  return (
    <S.Container>
      <S.MainText>데이터 거래 후 현금처럼 사용 가능</S.MainText>
      <S.SubText>교환권부터 금액권까지 다양하게! 거래당 평균 1,500 포인트 획득 가능</S.SubText>
      <S.BrandContainer>
        {/* <Arrow
          className="arrow"
          id="left"
          onMouseOver={handleDirectionMouseOver}
          onMouseOut={handleDirectionMouseOut}
        /> */}
        <S.IconFlow>
          <BannerScroll seq={seq} />
        </S.IconFlow>
        {/* <Arrow
          className="arrow"
          id="right"
          onMouseOver={handleDirectionMouseOver}
          onMouseOut={handleDirectionMouseOut}
        /> */}
      </S.BrandContainer>
    </S.Container>
  );
}

export default PointUsage;

const S = {
  Container: styled.section`
    background: rgba(190, 205, 255, 0.1);
    width: 100%;
    height: 743px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  `,
  MainText: styled.p`
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -1.15px;
    margin-top: 320px;
    margin-bottom: 10px;
    white-space: nowrap;
  `,
  SubText: styled.p`
    text-align: center;
    font-size: 30px;
    letter-spacing: -0.69px;
    margin-bottom: 80px;
    white-space: nowrap;
  `,
  BrandContainer: styled.div`
    width: calc(100% - 480px);
    min-height: 120px;
    position: relative;
    display: flex;
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .arrow:first-child {
      left: -50px;
    }
    .arrow:last-child {
      right: -50px;
      transform: translateY(-50%) rotate(180deg);
    }
  `,
  IconFlow: styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      width: 120px;
      height: 120px;
      margin-right: 30px;
    }
  `,
};
