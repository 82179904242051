import { useEffect, useRef } from "react";
import { BannerScrollType } from "@customType/Content";
import { styled } from "styled-components";

export default function BannerScroll({ seq }: BannerScrollType) {
  const banner = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let bannerLeft: number = 0;
    const img = Object.entries(banner.current!.children);
    img.map(([, imgEl]) => {
      (imgEl as HTMLElement).style.left = `${bannerLeft}px`;
      bannerLeft += 152;
    });
  }, []);

  useEffect(() => {
    const img = Object.entries(banner.current!.children);
    let first: number = 1;
    let last: number;
    let firstEl: HTMLElement;
    let lastEl: HTMLElement;
    let imgCnt: number = 0;
    img.map(([, imgEl]) => {
      imgEl.setAttribute("id", `${seq}${++imgCnt}`);
    });

    last = imgCnt;
    const intervalId = setInterval(() => {
      img.map(([, imgEl]) => {
        if (!banner.current) return;
        const leftPosition = (imgEl as HTMLElement).style.left.replace("px", "");
        (imgEl as HTMLElement).style.left =
          seq === "first" ? `${Number(leftPosition) - 3}px` : `${Number(leftPosition) + 3}px`;
      });

      firstEl = banner.current?.children.namedItem(`${seq}${first}`) as HTMLElement;
      lastEl = banner.current?.children.namedItem(`${seq}${last}`) as HTMLElement;

      const firstElPosition = firstEl.style.left.replace("px", "");
      const lastElPosition = lastEl.style.left.replace("px", "");
      const hiddenPosition = 120;
      const setPosition = 152;

      // 맨 앞거 맨뒤로 옮기기

      if (Number(firstElPosition) < -1 * hiddenPosition && seq === "first") {
        firstEl.style.left = `${Number(lastElPosition) + setPosition}px`;
        first++;
        last++;
      }

      // 맨 뒤에거 맨앞으로 옮기기
      if (Number(lastElPosition) > hiddenPosition && seq === "last") {
        lastEl.style.left = `${Number(firstElPosition) - setPosition}px`;
        first++;
        last++;
      }

      if (last > imgCnt) {
        last = 1;
      }

      if (first > imgCnt) {
        first = 1;
      }
    }, 15);

    return () => {
      clearInterval(intervalId);
    };
  }, [seq]);

  const addImgScroll = (idx: number) => {
    const result = [];

    for (let index = 1; index < 31; index++) {
      result.push(
        <S.ImageContainer key={index}>
          <img src={`images/icon/brand_icon${index}.png`} alt={`icon${index * idx}`} key={index} />
        </S.ImageContainer>
      );
    }

    return result;
  };

  return (
    <div className="banner" ref={banner}>
      {addImgScroll(1)}
      {addImgScroll(2)}
      {addImgScroll(3)}
      {/* {addImgScroll(4)}
      {addImgScroll(5)} */}
    </div>
  );
}

const S = {
  ImageContainer: styled.div`
    position: relative;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      /* transform: translate(50, 50); */
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
    }
  `,
};
