import { Connect, MydBigLogo } from "src/assets/svgs";
import { styled } from "styled-components";

function ToConnect() {
  return (
    <S.Container>
      <S.Box>
        <S.Left>
          <S.Top>
            <MydBigLogo className="big" /> <Connect className="connect" /> (기업전용)
          </S.Top>
          <S.MainText>
            마이디 사용자와
            <br />
            데이터 거래가 하고 싶다면?
          </S.MainText>
          <S.SubText>
            마이디 커넥트 서비스는
            <br />
            마이디 사용자의 데이터를 구매할 수 있는 서비스입니다.
          </S.SubText>
        </S.Left>
        <S.Right>
          <S.HomePageBtn href="https://service.biz.myd.world/" target="_blank" rel="noopener noreferrer">
            홈페이지 바로가기 &gt;
          </S.HomePageBtn>
        </S.Right>
      </S.Box>
    </S.Container>
  );
}

export default ToConnect;

const S = {
  Container: styled.section`
    width: 100%;
    height: 700px;
    background-color: #e7e9ee;
  `,
  Box: styled.div`
    width: calc(100% - 480px);
    margin: 0 240px;
    display: flex;
    justify-content: space-between;
  `,
  Left: styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
  `,
  Top: styled.div`
    display: flex;
    font-size: 40px;
    letter-spacing: -0.92px;
    align-items: center;
    white-space: nowrap;
    .big {
      margin-right: 26px;
    }
    .connect {
      margin-right: 10px;
    }
  `,
  MainText: styled.p`
    margin-top: 80px;
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1.38px;
    white-space: nowrap;
  `,
  SubText: styled.p`
    margin-top: 20px;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.92px;
    white-space: nowrap;
  `,
  Right: styled.div`
    margin-top: 250px;
  `,
  HomePageBtn: styled.a`
    cursor: pointer;
    width: 360px;
    height: 120px;
    color: white;
    background-color: #1890ff;
    border-radius: 20px;
    padding: 38px 63px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;
    display: block;
  `,
};
