import styled from "styled-components";

import DataTechExplained from "./DataTechExplained";

import { cls } from "src/theme/colors";
import { Appleogo, GoogleplayLogo } from "src/assets/svgs";
import OperateExample from "src/assets/images/operate_example.png";

function TransactionIntro() {
  return (
    <S.Container>
      <S.Box>
        <S.Left>
          <S.SubText>가장 안전한 데이터 거래를 위해</S.SubText>
          <S.MainText>
            마이데이터 거래 기반
            <br />
            데이터테크 플랫폼, <S.Highlight>마이디</S.Highlight>
          </S.MainText>
          <S.BtnBox>
            <S.AppDownloadBtn
              href="https://play.google.com/store/apps/details?id=io.snplab.myd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoogleplayLogo />
              Google Play
            </S.AppDownloadBtn>
            <S.AppDownloadBtn
              href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%94%94/id1576612543"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Appleogo />
              App Store
            </S.AppDownloadBtn>
          </S.BtnBox>
        </S.Left>
        <S.Right>
          <S.Img src={OperateExample} alt="operate_example" />
        </S.Right>
      </S.Box>
      <DataTechExplained />
    </S.Container>
  );
}

export default TransactionIntro;

const S = {
  Container: styled.section`
    width: 100%;
    height: 903px;
    background-color: ${cls.white};
    display: flex;
    position: relative;
  `,
  Box: styled.div`
    width: calc(100% - 480px);
    margin: 0 240px;
    display: flex;
    align-items: center;
  `,
  Left: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Right: styled.div``,
  Img: styled.img`
    width: 1101px;
    height: 800px;
  `,
  SubText: styled.p`
    font-size: 40px;
    letter-spacing: -0.92px;
    margin-bottom: 10px;
    white-space: nowrap;
  `,
  MainText: styled.p`
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1.38px;
    white-space: nowrap;
  `,
  Highlight: styled.span`
    color: ${cls.mainBlue5};
  `,
  BtnBox: styled.div`
    display: flex;
    margin-top: 80px;
  `,
  AppDownloadBtn: styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 80px;
    border-radius: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.2px;
    padding: 0;
    margin: 0;
    border: none;
    background: rgba(0, 0, 0, 0.8);
    margin-right: 20px;
    svg {
      margin-right: 8px;
    }
  `,
};
