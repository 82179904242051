import { styled } from "styled-components";

import { Snplab, Insta, Blog, Appstore, GooglePlay } from "src/assets/svgs";

function Footer() {
  return (
    <S.Container>
      <S.Box>
        <S.Left>
          <Snplab />
          <S.Middle>
            <p>서초구 매헌로 16 하이브랜드 14층 1414호</p>
            <p>
              주식회사 에스앤피랩 / 대표이사. 이재영 / <span>대표전화. 02-2038-2511 / 팩스. 050-7086-2235 /</span>{" "}
              <span>대표메일. hellodata@snplab.io</span>
            </p>
            <p>
              사업자번호. 889-87-01698 / <span>부가통신사업자. 제2-01-20-0274호 /</span>{" "}
              <span>통신판매업신고. 2020-서울성동-02763</span>
            </p>
            <br />
            <a
              href="https://cdn.v3.prod.myd.world/documents/privacy_policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              개인정보 처리방침
            </a>
            <br />
            <br />
            <p>Copyright ⓒ SNPLab Inc. All rights reserved.</p>
          </S.Middle>
        </S.Left>
        <S.Right>
          <S.Social>
            <a href="https://www.instagram.com/my_d.official" target="_blank" rel="noopener noreferrer">
              <Insta />
            </a>
            <a href="https://blog.naver.com/mydworld" target="_blank" rel="noopener noreferrer">
              <Blog />
            </a>
            <a
              href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%94%94/id1576612543"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Appstore />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=io.snplab.myd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GooglePlay />
            </a>
          </S.Social>
        </S.Right>
      </S.Box>
    </S.Container>
  );
}

export default Footer;

const S = {
  Container: styled.footer`
    background: #333333;
    width: 100%;
    height: 222px;
  `,
  Box: styled.div`
    width: calc(100% - 480px);
    padding-top: 56px;
    margin: 0 240px;
    display: flex;
    justify-content: space-between;
  `,
  Left: styled.div`
    display: flex;
  `,
  Middle: styled.div`
    color: white;
    font-size: 13px;
    margin-left: 120px;
    line-height: 15px;
    a {
      text-decoration: underline;
      color: white;
    }
  `,

  Right: styled.div``,
  Social: styled.div`
    display: flex;
    svg {
      margin-left: 24px;
    }
  `,
};
