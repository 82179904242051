import styled from "styled-components";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { MydLogoHighlight, Page8 as Page9 } from "src/assets/svgs";

import MainPage from "src/assets/images/page.png";
import Page1 from "src/assets/images/page1.png";
import Page2 from "src/assets/images/page2.png";
import Page3 from "src/assets/images/page3.png";
import Page4 from "src/assets/images/page4.png";
import Page5 from "src/assets/images/page5.png";
import Page6 from "src/assets/images/page6.png";
import Page7 from "src/assets/images/page7.png";
import Page8 from "src/assets/images/page8.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Preview() {
  return (
    <S.Container>
      <S.ScreenContainer>
        <Swiper
          centeredSlides
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          width={410}
        >
          <SwiperSlide>
            <S.Mockup src={MainPage} alt="preview" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page1} alt="preview1" />
            {/* <Page9 /> */}
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page2} alt="preview2" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page3} alt="preview3" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page4} alt="preview4" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page5} alt="preview5" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page6} alt="preview6" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page7} alt="preview7" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page8} alt="preview8" />
          </SwiperSlide>
        </Swiper>

        <span>마이디 서비스 미리보기</span>
      </S.ScreenContainer>
      <S.TextContainer>
        <S.SubText>나의 데이터, 직접 거래하자!</S.SubText>

        <S.MainTextTop>
          일상에서 <MydLogoHighlight />를 모으다
        </S.MainTextTop>
        <S.MainText>이제는 마이디 하세요!</S.MainText>
      </S.TextContainer>
    </S.Container>
  );
}

export default Preview;

const S = {
  Container: styled.section`
    width: 100%;
    height: 1039px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .swiper-wrapper {
      width: 410px;
    }
  `,
  ScreenContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 341px;
    span {
      text-align: center;
      font-size: 20px;
      letter-spacing: -0.46px;
      margin-top: 20px;
    }
  `,
  Mockup: styled.img`
    width: 385px;
    height: 800px;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 135px;
  `,
  SubText: styled.p`
    font-size: 40px;
    letter-spacing: -0.92px;
    white-space: nowrap;
  `,
  MainText: styled.p`
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1.38px;
    white-space: nowrap;
  `,
  MainTextTop: styled.p`
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1.38px;
    display: flex;
    align-items: center;
    margin-top: 22px;
    white-space: nowrap;
    svg {
      margin: 0 4px 0 16px;
      align-self: flex-end;
    }
  `,
};
