export const cls = {
    white: "#ffffff",
    black: "#000000",
    mainBlack: "#222222",
    mainBlue1: "#BECDFF",
    mainBlue2: "#92AAFF",
    mainBlue5: "#2651E8",
    mainBlue7: "#082AA4",
    pink: "#fc596c",
    blue0: "#eff6ff",
    blue1: "#ced9ff",
    blue2: "#adbfff",
    blue3: "#809cff",
    blue4: "#6484f1",
    overlay: "#00000080",
    gray0: "#ffffff",
    gray1: "#f4f4f4",
    gray2: "#dddddd",
    gray3: "#d9d9d9",
    gray4: "#a5a5a5",
    gray5: "#7c7c7c",
    gray6: "#4b4b4b",
    gray7: "#222222",
    transparent0: "#00000050",
    transparent1: "#00000080",
} as const;
