/* eslint-disable no-return-assign */
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Header, Footer } from "@components/index";

import TransactionIntro from "@components/TransactionIntro";
import PointUsage from "@components/PointUsage";
import Preview from "@components/Preview";
import ToConnect from "@components/ToConnect";
import { styled } from "styled-components";

function App() {
  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://m.myd.world";
    }
  }, []);

  return (
    <S.Container>
      <Header />
      <TransactionIntro />
      <PointUsage />
      <Preview />
      <ToConnect />
      <Footer />
    </S.Container>
  );
}

export default App;

const S = {
  Container: styled.div`
    min-width: 2200px;
  `,
};
