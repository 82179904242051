import { MydLogo } from "src/assets/svgs";
import { cls } from "src/theme/colors";
import styled from "styled-components";

export default function Header() {
  return (
    <>
      <S.Container>
        <S.Content>
          <MydLogo />
          <S.Nav>
            <span data-state="active">마이디 소개</span>
            <a href="https://relay.cert.myd.world">공동인증서 복사하기</a>
          </S.Nav>
        </S.Content>
      </S.Container>
      <S.UnderNeath />
    </>
  );
}

const S = {
  Container: styled.header`
    width: 100%;
    color: ${cls.mainBlack};
    text-align: right;
    font-size: 18px;
    font-family: Noto Sans KR;
    background: ${cls.white};
    position: absolute;
    top: 0;
    left: 0;
  `,
  Content: styled.div`
    width: calc(100% - (240 * 2) px);
    height: 121px;
    margin: 0 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Nav: styled.nav`
    span,
    a {
      margin-left: 40px;
      font-size: 18px;
      padding-bottom: 6px;
      color: ${cls.mainBlack};
      &:hover {
        border-bottom: 3px solid ${cls.mainBlack};
      }
      &[data-state="active"] {
        border-bottom: 3px solid ${cls.mainBlack};
      }
    }
  `,
  UnderNeath: styled.div`
    width: 100%;
    height: 121px;
  `,
};
